import React, { useState } from "react";
import { Webinar as WebinarType, WebinarQueryResult } from "app-types/webinar";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/global.style";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  Category,
  Content,
  Duration,
  HeaderContainer,
  HeadingContainer,
  HeadingDescription,
  Host,
  HostContent,
  HostImage,
  HostName,
  HostRole,
  MetadataBox,
  MetadataContent,
  MetadataTitle,
  OtherWebinar,
  OtherWebinarContainer,
  RegisterContainer,
  RegisterForm,
  SeeMoreLink,
  WebinarTitle,
} from "./index.style";
import { GatsbyImage } from "gatsby-plugin-image";
import Loop from "components/core/Loop";
import { graphql } from "gatsby";
import WebinarComponent from "components/ui/base/Webinar";
import Heading from "components/core/Typography/Heading";
import Fade from "components/core/Animation/extended/Fade";
import Icon from "components/core/Icon";
import SafeLink from "components/core/SafeLink";
import Tooltip from "components/core/Tooltip";
import Input from "components/core/Input";
import Button from "components/core/Button";
import Spinner from "components/core/Spinner";
import { validate } from "utils/email";
import { useRegister } from "networking/useWebinar";
import { getTracking } from "utils/tracking";
import { CTAColor } from "app-types/color";
import { useSendForm } from "networking/useForms";
import useEventLeadGenerated from "events/useEventLeadGenerated";

export interface WebinarProps {
  pageContext: {
    webinar: WebinarType;
  };
  data: WebinarQueryResult;
  location: Location;
}
const Webinar = ({ pageContext, data, location }: WebinarProps) => {
  const { t } = useTranslation();
  const { fireEvent } = useEventLeadGenerated();

  const webinar: WebinarType = pageContext.webinar;
  const otherWebinars = data.webinars.nodes;
  const sendRegister = useRegister();
  const sendForm = useSendForm();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !validate(email)) {
      setIsError(true);

      return;
    }

    setIsLoading(true);
    setIsSuccess(false);

    sendRegister(webinar.eventId, email, firstName, lastName, getTracking())
      .then(() => {
        sendForm(email, {
          name: `${firstName} ${lastName}`,
          utm_source: "Customerly",
          utm_medium: "webinar",
          utm_campaign: webinar.slug,
        })
          .then(() => {
            setIsError(false);
            setIsSuccess(true);
            setIsLoading(false);
            fireEvent({ step: "submit" });
          })
          .catch(() => {
            setIsError(false);
            setIsSuccess(true);
            setIsLoading(false);
          });
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  };

  return (
    <GlobalWrapper withLayout withCta={false} location={location}>
      <SEO
        title={webinar.title}
        description={webinar.shortDescription}
        image={{
          imageData: webinar.image.localFile.childImageSharp.gatsbyImageData,
          alt: webinar.image.alt,
        }}
        structuredData={[]}
      />

      <HeaderContainer>
        <PageContainer>
          <Fade direction={"up"}>
            <Row>
              <Col lg={5} sm={12}>
                <HeadingContainer>
                  <Heading
                    level={1}
                    weight="bold"
                    dangerouslySetInnerHTML={{ __html: webinar.title }}
                  />
                </HeadingContainer>
                <Row>
                  <Col>
                    <HeadingDescription
                      dangerouslySetInnerHTML={{
                        __html: webinar.shortDescription,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={2} sm={12} />
              <Col lg={5} sm={12}>
                <RegisterContainer>
                  <Heading level={3}>
                    {t("templates.webinar.shareDetails")}
                  </Heading>

                  <Tooltip
                    content={t("templates.webinar.formError")}
                    type={"custom"}
                    position={"bottom-center"}
                    show={isError}
                  >
                    <RegisterForm onSubmit={onSubmit}>
                      <Input
                        name={"firstName"}
                        type={"text"}
                        placeholder={t(
                          "templates.webinar.placeholderFirstName"
                        )}
                        required
                        disabled={isSuccess || isLoading}
                        onChange={(e: any) => {
                          setIsError(false);
                          setIsSuccess(false);
                          setFirstName(e.target.value);
                        }}
                      />
                      <Input
                        name={"lastName"}
                        type={"text"}
                        placeholder={t("templates.webinar.placeholderLastName")}
                        required
                        disabled={isSuccess || isLoading}
                        onChange={(e: any) => {
                          setIsError(false);
                          setIsSuccess(false);
                          setLastName(e.target.value);
                        }}
                      />
                      <Input
                        name={"email"}
                        type={"email"}
                        placeholder={t("templates.webinar.placeholderEmail")}
                        required
                        disabled={isSuccess || isLoading}
                        onChange={(e: any) => {
                          setIsError(false);
                          setIsSuccess(false);
                          setEmail(e.target.value);
                        }}
                      />
                      <Button
                        disabled={isSuccess || isLoading}
                        fullWidth
                        onClick={onSubmit}
                        ctaColor={CTAColor.Yellow}
                      >
                        <Spinner isLoading={isLoading}>
                          {isSuccess
                            ? t("templates.webinar.registered")
                            : t("templates.webinar.register")}
                        </Spinner>
                      </Button>
                    </RegisterForm>
                  </Tooltip>
                </RegisterContainer>
              </Col>
            </Row>
          </Fade>
        </PageContainer>
      </HeaderContainer>

      <PageContainer>
        <Container>
          <Row>
            <Col md={3}>
              <MetadataBox>
                <MetadataTitle>{t("templates.webinar.hostedBy")}</MetadataTitle>
                <MetadataContent>
                  <Host>
                    <HostImage>
                      <GatsbyImage
                        image={
                          webinar.hostImage.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={webinar.hostName}
                      />
                    </HostImage>
                    <HostContent>
                      <HostName>{webinar.hostName}</HostName>
                      <HostRole>{webinar.hostRole}</HostRole>
                    </HostContent>
                  </Host>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>{t("templates.webinar.duration")}</MetadataTitle>
                <MetadataContent>
                  <Duration>{webinar.durationMinutes} min</Duration>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>{t("templates.webinar.category")}</MetadataTitle>
                <MetadataContent>
                  <Category>{webinar.category}</Category>
                </MetadataContent>
              </MetadataBox>
            </Col>
            <Col md={9}>
              <WebinarTitle>{webinar.title}</WebinarTitle>
              <Content
                dangerouslySetInnerHTML={{
                  __html:
                    webinar.longDescription.childMarkdownRemark.html.replace(
                      /\n/g,
                      "<br />"
                    ),
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <OtherWebinarContainer>
                <OtherWebinar>{t("templates.webinar.others")}</OtherWebinar>
                <SeeMoreLink as={SafeLink} to={"/webinars/"}>
                  {t("templates.webinar.seeMore")}
                  <Icon type={"arrow-right"} size={16} />
                </SeeMoreLink>
              </OtherWebinarContainer>
              <Row>
                <Loop items={otherWebinars}>
                  {(webinar: WebinarType) => (
                    <Col md={6} sm={12} key={webinar.slug}>
                      <WebinarComponent webinar={webinar} />
                    </Col>
                  )}
                </Loop>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageContainer>
    </GlobalWrapper>
  );
};

export default Webinar;

export const webinarDetailPageQuery = graphql`
  query {
    webinars: allContentfulWebinar(
      sort: { fields: createdAt, order: DESC }
      limit: 2
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        id
        slug
        title
        category
        shortDescription
        longDescription {
          childMarkdownRemark {
            html
          }
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt: title
        }
        hostName
        hostRole
        hostImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        durationMinutes
      }
    }
  }
`;
