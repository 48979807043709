import styled from "styled-components";
import { Title } from "components/core/Typography/Title";
import { StyledHeading } from "components/core/Typography/Heading/index.style";
import { UnderlinedLink } from "styles/global.style";
import { media } from "styled-bootstrap-grid";
import { StyledButton } from "components/core/Button/index.style";
import { StyledInput } from "components/core/Input/index.style";

const HeaderContainer = styled.div`
  padding-bottom: 64px;
  padding-top: 96px;
  margin-bottom: 32px;
  background: #bee8ff33;

  ${media.sm`
    padding-top: 140px;
    margin-bottom: 96px;
  `}
`;

const HeadingContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;

  ${StyledHeading} {
    color: ${(props) => props.theme.titleColor};
  }
`;

const HeadingDescription = styled.p`
  margin: 0;
`;

const RegisterContainer = styled.div`
  margin-top: 32px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  justify-content: center;
  padding: 32px;
  border-radius: ${(props) => props.theme.borderRadius};

  ${StyledHeading} {
    color: ${(props) => props.theme.titleColor};
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 1.3em;
  }
`;

const RegisterForm = styled.form`
  ${StyledInput} {
    margin-bottom: 16px;
    border: 2px solid ${(props) => props.theme.baseLightColor};
  }

  ${StyledButton} {
    margin-top: 4px;
    font-size: 16px;
  } ;
`;

const WebinarTitle = styled(Title)`
  font-size: 22px;
  margin-top: 1.1em;
  line-height: 1.2;

  ${media.lg`
    font-size: 24px;
    margin-top: 1.1em;
  `}
`;

const Content = styled.div`
  p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
  }
`;

const MetadataBox = styled.div``;

const MetadataTitle = styled.span`
  display: block;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2;
  color: ${(props) => props.theme.titleColor};
  margin-top: 1.5em;
  margin-bottom: 0.3em;
`;

const MetadataContent = styled.div``;

const Host = styled.div`
  display: flex;
  align-items: center;
`;

const HostImage = styled.div`
  display: flex;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
`;
const HostContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const HostName = styled.span`
  display: block;
`;

const HostRole = styled.span`
  display: block;
`;

const Duration = styled.span`
  display: block;
`;

const Category = styled.span`
  display: block;
`;

const OtherWebinarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 96px;

  ${media.sm`
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  `}
`;

const OtherWebinar = styled(Title)`
  margin: 0;
`;

const SeeMoreLink = styled(UnderlinedLink)`
  margin-right: 0;
  margin-top: 8px;

  svg {
    margin-left: 8px;
    stroke: ${(props) => props.theme.accentColor};
  }

  ${media.sm`
    margin-right: 32px;
    margin-top: 0;
  `}
`;

export {
  HeaderContainer,
  HeadingContainer,
  HeadingDescription,
  RegisterContainer,
  RegisterForm,
  MetadataBox,
  MetadataTitle,
  MetadataContent,
  HostName,
  HostRole,
  Duration,
  Category,
  SeeMoreLink,
  OtherWebinar,
  OtherWebinarContainer,
  WebinarTitle,
  Content,
  Host,
  HostImage,
  HostContent,
};
